import Cookies from 'universal-cookie';

import bridgeData from '@/_mappers/bridge-data';
import { ROUTES } from '@/_navigation/routes';
import { FILTER } from '@/_static/constants';

export const setCookie = (key, value, days) => {
  const cookies = new Cookies();
  const date = new Date();
  date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
  const expiration = new Date(date.toUTCString());
  const options = {
    path: ROUTES.QUALIFY.link,
    expires: expiration,
  };
  cookies.set(key, value, options);
};

export const getCookie = (key) => {
  const cookies = new Cookies();
  if (cookies.get(key)) {
    return cookies.get(key);
  }
  return null;
};

export const initCampaignObj = () => {
  let campaignObj = {};
  if (getParameterByName('campaign')) {
    const queryString = window.location.search;
    campaignObj = {
      referral: document.referrer,
      firstPage: window.location.href,

      campaign: getParameterByName('campaign') ?? '',
      utm_campaign: getParameterByName('utm_campaign') ?? '',
      utm_source: getParameterByName('utm_source') ?? '',
      utm_medium: getParameterByName('utm_medium') ?? '',
      AdGroup: getParameterByName('AdGroup') ?? '',
      Keyword: getParameterByName('Keyword') ?? '',

      gclid: getParameterByName('gclid') ?? '',
      afid: getParameterByName('afid') ?? '',
      hitid: getParameterByName('hitid') ?? '',
      subid: getParameterByName('subid') ?? '',
      params: queryString,
    };
    setCookie('campaignObj', JSON.stringify(campaignObj), 2);
  } else if (getCookie('campaignObj')) {
    campaignObj = JSON.parse(JSON.stringify(getCookie('campaignObj')));
  }
  return campaignObj;
};

/* eslint-disable no-unused-vars */
export const getCurrentObj = (currentPage) => {
  let obj = {};
  for (const [key, value] of Object.entries(bridgeData)) {
    if (Object.keys(value)[0] === currentPage) {
      obj = value[Object.keys(value)[0]];
      break;
    }
    for (const [key2, value2] of Object.entries(value.questions)) {
      if (Object.keys(value2)[0] === currentPage) {
        obj = value2[Object.keys(value2)[0]];
        break;
      }
    }
  }
  return obj;
};

/* eslint-disable no-unused-vars */
export const getCurrentObjFromFieldApi = (api) => {
  let obj = {};
  for (const [key, value] of Object.entries(bridgeData)) {
    for (const [key2, value2] of Object.entries(value.questions)) {
      for (const [key3, value3] of Object.entries(value2)) {
        if (value3?.api === api) {
          obj = value3;
          break;
        }
      }
    }
  }
  return obj;
};

export const getCurrentQuestions = (currentPage) => {
  let obj = {};
  for (const [key, value] of Object.entries(bridgeData)) {
    if (Object.keys(value)[0] === currentPage) {
      obj = value[Object.keys(value)[0]];
      break;
    }
  }
  const { questions } = obj;
  if (questions) {
    return questions;
  }
  return null;
};

export const replaceWithName = (str, leadObj) => {
  return str.includes('{name}') ? str.replace('{name}', leadObj?.First_Name__c) : str;
};

export const updateLocalStorage = (localKey, obj) => {
  const data = localStorage.getItem(localKey) ? JSON.parse(localStorage.getItem(localKey)) : {};
  let newData = null;
  if (data) {
    newData = { ...data, ...obj };
  }

  localStorage.setItem(localKey, JSON.stringify(newData));
};

export const getLocalStorageValueByKey = (property) => {
  const formData = localStorage.getItem('formData') ? JSON.parse(localStorage.getItem('formData')) : {};
  if (formData.length > 0) {
    for (const [key, value] of Object.entries(formData)) {
      if (key === property) {
        return value;
      }
    }
  }
  return null;
};

export const getParameterByName = (name, url = window.location.href) => {
  const n_name = name.replace(/[[\]]/g, '\\$&');
  const regex = new RegExp(`[?&]${n_name}(=([^&#]*)|&|#|$)`);
  const results = regex.exec(url);
  if (!results) return null;
  if (!results[2]) return '';
  return decodeURIComponent(results[2].replace(/\+/g, ' '));
};

export const buildScoreMap = (fd) => {
  const getValue = (key) => {
    for (const [key1, value] of Object.entries(fd)) {
      if (key === key1) {
        return value;
      }
    }
    return null;
  };

  // build score mapping
  const objArr = [];
  for (const [key] of Object.entries(fd)) {
    if (!FILTER.has(key)) {
      objArr.push(getCurrentObjFromFieldApi(key));
    }
  }
  const scoreMap = new Map();
  /* eslint-disable no-unused-vars */
  for (const [key, value] of Object.entries(objArr)) {
    const { api, category } = value;
    const fieldVal = api && getValue(api);
    if (value?.list && value.list.length > 0) {
      for (const [key2, value2] of Object.entries(value.list)) {
        if (value2?.options && value2.options.length > 0) {
          for (const obj of value2.options) {
            const { option, score } = obj;
            if (option === fieldVal) {
              if (scoreMap.get(category) != null) {
                scoreMap.set(category, scoreMap.get(category) + score);
              } else {
                scoreMap.set(category, score);
              }
            }
          }
        }
      }
    }
  }
  return scoreMap;
};
