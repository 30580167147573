import { useEffect, useState } from 'react';

import { Text, Accordion, AccordionContent, AccordionItem, AccordionTrigger, Progressbar } from 'atp-react-ui';

import RESULTS_MAPPING_APIKEY from '@/_mappers/api-result-mappers';
import PurpleMinus from '@/_static/assets/purpleMinus.svg?react';
import PurplePlus from '@/_static/assets/purplePlus.svg?react';
import { replaceWithName, buildScoreMap } from '@/_utils/actions';

const data = [
  {
    title: 'General Education',
    body: `Use our Credit by Exam prep courses to fast track your Gen Eds.`,
    average: null,
    score: 24,
  },
  {
    title: 'Entrance Exam (TEAS)',
    body: `Use our live online instructor led prep courses to pass your Entrance Exam the 1st time.`,
    average: null,
    score: 38,
  },
  {
    title: 'Core RN Courses',
    body: `Use our on-demand knowledge base to get real-time Core Nursing Course answers.`,
    average: null,
    score: 50,
  },
  {
    title: 'Licensure Exam (NCLEX-RN)',
    body: `Use our live online instructor led prep courses to pass your Nursing Licensure Exam the 1st time.`,
    average: null,
    score: 82,
  },
];

function ResultsCard(props) {
  const { currentObj, leadObj } = props;

  const scoreAverage = (n) => {
    let s = '';
    if (n <= 25) {
      s = 'Low';
    } else if (n > 25 && n < 65) {
      s = 'Medium';
    } else if (n >= 65) {
      s = 'High';
    }
    return s;
  };

  const formData = localStorage.getItem('formData') ? JSON.parse(localStorage.getItem('formData')) : null;

  const scoreMap = buildScoreMap(formData);

  // update scoring
  for (const obj of data) {
    const { title } = obj;

    const score = scoreMap.get(title === 'Licensure Exam (NCLEX-RN)' ? 'Exit Exam' : title);
    obj.average = scoreAverage(score);
    obj.score = score;
  }

  return (
    <>
      <Text as="h1" className="font-semibold text-primary">
        {replaceWithName(currentObj.header, leadObj)}
      </Text>

      <Text
        className="pb-8 pt-2 text-base text-accent-medium"
        dangerouslySetInnerHTML={{ __html: replaceWithName(currentObj.subheader, leadObj) }}
      />

      <Accordion type="multiple" collapsible="true" className="w-full">
        {data.map((obj, index) => {
          const { title, body, average, score } = obj;

          let n_score = score;
          let n_average = average;

          if (RESULTS_MAPPING_APIKEY.get(title) != null) {
            const fd = localStorage.getItem('formData') ? JSON.parse(localStorage.getItem('formData')) : null;
            const api_key = RESULTS_MAPPING_APIKEY.get(title);
            let labelValue = null;
            if (fd) {
              labelValue = fd[api_key];
            }
            if (labelValue) {
              if (labelValue.includes(`I've passed`) && labelValue !== `I've passed some, but not all of them.`) {
                n_score = 100;
                n_average = 'COMPLETED';
              }
            }
          }

          return (
            <AccordionItem key={title} value={`item-${index + 1}`}>
              <AccordionTrigger className="py-2">
                <div className="flex w-full flex-col">
                  <NewProgress value={n_score} isComplete={n_average === 'COMPLETED'} />
                  <div className="mt-1 flex w-full items-center justify-between">
                    <div
                      className={`${
                        n_average === 'COMPLETED' ? 'text-secondary' : 'text-primary'
                      } flex items-center gap-x-3 text-base font-medium`}
                    >
                      <span className="group-data-[state=open]:hidden">
                        <PurplePlus />
                      </span>
                      <span className="group-data-[state=closed]:hidden">
                        <PurpleMinus />
                      </span>
                      {title}
                    </div>

                    <span
                      className={`font-sans italic ${
                        n_average === 'COMPLETED' ? 'text-secondary' : 'text-primary-medium'
                      }`}
                    >
                      {n_average}
                    </span>
                  </div>
                </div>
              </AccordionTrigger>
              <AccordionContent>
                <div className="pb-4 pl-5 pt-0 text-base font-medium text-accent-light">{body}</div>
              </AccordionContent>
            </AccordionItem>
          );
        })}
      </Accordion>
    </>
  );
}

export default ResultsCard;

function NewProgress({ value, isComplete }) {
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    setTimeout(() => {
      setProgress(value);
    }, 100);
  }, [value]);
  return (
    <Progressbar
      value={progress}
      indicatorClassName={isComplete ? 'bg-secondary-medium' : ''}
      className={isComplete ? 'bg-secondary-x-light' : ''}
    />
  );
}
