import { twMerge } from 'atp-react-ui';
import ReactPlayer from 'react-player';

import placeHolder from '@/_static/assets/Play-Button-Video.png';
import { STYLE } from '@/_static/styles';

import DangerousText from './DangerousText';

export function VideoCard({
  currentObj,
  updateCategory,
  submitLeadQualification,
  navigate,
  breakpoints,
  handleGoogleEvent,
}) {
  const handleOnEnd = () => {
    handleGoogleEvent('video_ended');
    if (updateCategory(currentObj?.category)) {
      submitLeadQualification();
    }
    navigate(currentObj.nextPage);
  };

  const handlePlayIconHeight = () => {
    switch (breakpoints) {
      case 'xxs':
        return 150;
      case 'xs':
        return 178.88;
      default:
        return 328;
    }
  };

  return (
    <>
      <div>
        <ReactPlayer
          controls
          url={currentObj.videoLink}
          width="100%"
          height="100%"
          onEnded={handleOnEnd}
          onStart={() => handleGoogleEvent('video_started')}
          playing
          playIcon={<div style={{ minHeight: handlePlayIconHeight() }} />}
          light={placeHolder}
        />
      </div>

      <div className={twMerge(STYLE.header, 'my-2 text-center !font-medium')}>
        <DangerousText>{currentObj.header}</DangerousText>
      </div>
      <div
        className={twMerge(STYLE.subHeader, 'text-center !font-light')}
        dangerouslySetInnerHTML={{ __html: currentObj.subheader }}
      />
    </>
  );
}
