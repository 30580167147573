import { useState } from 'react';

import { useNavigate, Outlet, useLocation } from 'react-router-dom';

import { getCurrentObj, getCurrentQuestions, getTimeValidity, useUrlParams, updateLocalStorage } from '@/_utils';
import googleAnalytics4 from '@/_utils/google-analytics-4';

import { DataContext } from './data-context';

export function DataProvider() {
  const nav = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [enableButton, setEnableButton] = useState(false);

  const { dev_setPortal } = useUrlParams();
  const { pathname: currentPage } = useLocation();
  const currentObj = getCurrentObj(currentPage);
  const currentQuestions = getCurrentQuestions(currentPage);
  const sendGoogleEvent = googleAnalytics4.event;
  const { isValidTimeToMeet } = getTimeValidity(dev_setPortal);

  const formState = useState(localStorage.getItem('formData') ? JSON.parse(localStorage.getItem('formData')) : null);

  const updateCategory = (str) => {
    if (str) {
      updateLocalStorage('formData', { Stage__c: str });
      return true;
    }
    return false;
  };

  return (
    <DataContext.Provider
      value={{
        nav,
        dev_setPortal,
        currentPage,
        currentObj,
        currentQuestions,
        sendGoogleEvent,
        isValidTimeToMeet,

        isLoading,
        setIsLoading,

        enableButton,
        setEnableButton,

        formState,
        updateCategory,
      }}
    >
      <Outlet />
    </DataContext.Provider>
  );
}
