import { Suspense, lazy } from 'react';

import { Layout } from './layout';
import { Loading } from './loading';

const MeetingConfirmation = lazy(() => import('./meeting-confirmation'));

export function MeetingConfirmationPage() {
  return (
    <Layout>
      <Suspense fallback={<Loading />}>
        <MeetingConfirmation />
      </Suspense>
    </Layout>
  );
}
