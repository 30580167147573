import { useEffect } from 'react';

import { Outlet } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';

import googleAnalytics4 from '@/_utils/google-analytics-4';

export function Administrator() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
    setTimeout(() => {
      googleAnalytics4.update(pathname);
    }, 100);
  }, [pathname]);

  return (
    <>
      <Outlet />
      <ToastContainer
        limit={5}
        position="bottom-left"
        autoClose={6000}
        hideProgressBar
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnHover
      />
    </>
  );
}
