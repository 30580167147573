const useStyles = () => ({
  header: 'font-serif font-medium text-gray-800 text-base md:text-2xl lg:text-3xl xl:text-4xl',
  stepHeader: 'font-sans text-orange-500 text-center uppercase font-bold text-xs md:text-sm',
  cardContainer: 'bg-white shadow-md rounded-lg mt-4',
  cardTableHeader: 'font-sans font-bold text-left',
  cardTableContent: 'font-sans font-normal text-left',
  cardBtn: 'border-none bg-transparent p-5 w-full cursor-pointer',
  list: 'list-none font-ibm-plex-sans font-bold text-base md:text-lg text-gray-800 w-full p-4',
  smallHeader: 'text-xs uppercase',
  subheader2: 'font-ibm-plex-sans font-normal text-base text-gray-800',

  containTable: 'bg-white shadow-card-primary-x-light px-4 py-3.5 rounded-xl rounded-bl-none mt-2.5',
  table: 'font-ibm-plex-sans font-normal w-full text-xxs lg:text-sm',
  customTd: 'p-1.5 lg:p-2.5 border-t border-gray-200 leading-4',
  customTableText: 'font-caveat text-base md:text-lg font-medium text-gray-800',
  tableHeader: 'font-medium text-gray-800 tracking-x-wide mb-1.5 text-2xl font-serif',
  disableCardBtn: 'opacity-50 pointer-events-none',
});

export default useStyles;
