import { useSearchParams } from 'react-router-dom';

export function useUrlParams() {
  const [searchParams, setSearchParams] = useSearchParams();
  const dev_setPortal = searchParams.get('set_portal');

  const deleteParams = (p) => {
    p.forEach((i) => {
      searchParams.delete(i);
    });
    return searchParams;
  };

  return {
    searchParams,
    setSearchParams,
    deleteParams,
    dev_setPortal,
  };
}

export function openWindow(url) {
  window.location.href = url;
}
