import { useMutation } from '@tanstack/react-query';

import { prepApiClient } from '@/_utils';

export function useRetellAI(lead_id) {
  return useMutation({
    mutationFn: async () =>
      prepApiClient.request({
        endpoint: 'cron/retell-lead?lead_id=' + lead_id,
        headers: {
          'x-api-key': 'C6MVG9rFJvQRVOvk67vZhP71kQurCIJ1rCrorRd38JIkuKpiRDyc5bWKX8R6dvXWvrY6AtbE9r46scekujQVhP',
        },
      }),
  });
}
