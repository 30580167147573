import { ROUTES } from '@/_navigation/routes';

import CATEGORIES from './category-mapper';

const GOOGLE_EVENT_MAPPING = {
  [ROUTES.MINDSET_Q3.link]: {
    category: 'Lead Qualification Stage',
    action: CATEGORIES.MINDSET_QUALIFY,
    label: CATEGORIES.MINDSET_QUALIFY,
  },
  [ROUTES.BRIDGE_GEN_ED_END.link]: {
    category: 'Lead Qualification Stage',
    action: CATEGORIES.GENERAL_EDUCATION,
    label: CATEGORIES.GENERAL_EDUCATION,
  },
  [ROUTES.BRIDGE_GEN_ED_END_PASSED_END.link]: {
    category: 'Lead Qualification Stage',
    action: CATEGORIES.GEN_ED_REPATH_PASSED_ALL,
    label: CATEGORIES.GEN_ED_REPATH_PASSED_ALL,
  },
  [ROUTES.BRIDGE_ENTRANCE_EXAM_END.link]: {
    category: 'Lead Qualification Stage',
    action: CATEGORIES.ENTRANCE_EXAM,
    label: CATEGORIES.ENTRANCE_EXAM,
  },
  [ROUTES.BRIDGE_ENTRANCE_EXAM_PASSED_END.link]: {
    category: 'Lead Qualification Stage',
    action: CATEGORIES.ENTRANCE_EXAM,
    label: CATEGORIES.ENTRANCE_EXAM,
  },
  [ROUTES.BRIDGE_CORE_NURSING_END.link]: {
    category: 'Lead Qualification Stage',
    action: CATEGORIES.CORE_NURSING_COURSES,
    label: CATEGORIES.CORE_NURSING_COURSES,
  },
  [ROUTES.BRIDGE_CORE_NURSING_PASSED_END.link]: {
    category: 'Lead Qualification Stage',
    action: CATEGORIES.REPATH_PASSED_CORE_RN_COURSES,
    label: CATEGORIES.REPATH_PASSED_CORE_RN_COURSES,
  },
  [ROUTES.BRIDGE_EXIT_EXAM_END.link]: {
    category: 'Lead Qualification Stage',
    action: CATEGORIES.EXIT_EXAM,
    label: CATEGORIES.EXIT_EXAM,
  },
  [ROUTES.BRIDGE_EXIT_EXAM_PASSED_END.link]: {
    category: 'Lead Qualification Stage',
    action: CATEGORIES.EXIT_EXAM,
    label: CATEGORIES.EXIT_EXAM,
  },
  [ROUTES.BRIDGE_PLAN_WO_PRE_BUY_READY.link]: {
    category: 'Lead Qualification Stage',
    action: CATEGORIES.READINESS,
    label: CATEGORIES.READINESS,
  },
  bp: {
    category: 'Lead Qualification Stage',
    action: CATEGORIES.BP_PRE_BUY_PURCHASED,
    label: CATEGORIES.BP_PRE_BUY_PURCHASED,
  },
  reservation_confirmed: {
    category: 'Lead Qualification Stage',
    action: 'Reservation Confirmed',
    label: 'Reservation Confirmed',
  },
  sn: {
    category: 'Lead Qualification Action',
    action: 'Meet With Advisor Now',
    label: 'Meet With Advisor Now',
  },
  ca: {
    category: 'Lead Qualification Action',
    action: 'Calendly Appointment Initiated',
    label: 'Calendly Appointment Initiated',
  },
  video_started: {
    category: 'Lead Qualification Action',
    action: 'Video Started',
    label: 'Video Started',
  },
  video_ended: {
    category: 'Lead Qualification Action',
    action: 'Video Ended',
    label: 'Video Ended',
  },
  start_qualify: {
    category: 'Lead Qualification Stage',
    action: 'Start Qualify',
    label: 'Start Qualify',
  },
  [ROUTES.MINDSET_R_Q2.link]: {
    category: 'Lead Qualification Stage',
    action: CATEGORIES.MINDSET_REPATH_NOT_READY,
    label: CATEGORIES.MINDSET_REPATH_NOT_READY,
  },
  '/r/2': {
    category: 'Lead Qualification Stage',
    action: CATEGORIES.MINDSET_REPATH_NOT_READY,
    label: CATEGORIES.MINDSET_REPATH_NOT_READY,
  },
  pre_buy: {
    category: 'Lead Qualification Action',
    action: 'Pre-buy',
    label: 'Pre-buy',
  },
  no_pre_buy: {
    category: 'Lead Qualification Action',
    action: 'Continue w/out pre-buy',
    label: 'Continue w/out pre-buy',
  },
  pre_buy_meetNow: {
    category: 'Lead Qualification Action',
    action: 'Pre-Buy_Meet Now',
    label: 'Pre-Buy_Meet Now',
  },
  pre_buy_calendly: {
    category: 'Lead Qualification Action',
    action: 'Pre-Buy_Calendly Start',
    label: 'Pre-Buy_Calendly Start',
  },
  no_pre_buy_meetNow: {
    category: 'Lead Qualification Action',
    action: 'Cont W/O Pre-Buy_Meet Now',
    label: 'Cont W/O Pre-Buy_Meet Now',
  },
  no_pre_buy_sch: {
    category: 'Lead Qualification Action',
    action: 'Cont W/O Pre-Buy_Sched Mtg',
    label: 'Cont W/O Pre-Buy_Sched Mtg',
  },
  no_pre_buy_calendly: {
    category: 'Lead Qualification Action',
    action: 'Cont W/O Pre-Buy_Calendly Start',
    label: 'Cont W/O Pre-Buy_Calendly Start',
  },
};

export default GOOGLE_EVENT_MAPPING;
