import { Skeleton } from 'atp-react-ui';

import CalendarIcon from '@/_static/assets/calendarIcon.svg?react';

export function Loading() {
  return (
    <>
      <div className="relative flex self-center md:-ml-[3px] md:mt-[3px]">
        <CalendarIcon />
        <div className="absolute left-1/2 top-1/2 flex -translate-x-1/2 -translate-y-1/2 flex-col items-center gap-1 pl-6 pt-12">
          <Skeleton className="h-6 w-14" />
          <Skeleton className="h-6 w-24" />
        </div>
      </div>
      <div className="mt-4 flex flex-col items-center gap-3 md:items-start md:pr-16">
        <Skeleton className="h-6 w-2/3 md:w-64" />
        <Skeleton className="h-8 w-3/4 md:w-96" />
        <Skeleton className="h-8 w-3/4 md:w-96" />
        <Skeleton className="h-6 w-2/3 md:w-64" />
      </div>
    </>
  );
}
