import { initChat } from '@/_utils';

export function ChatRnD() {
  initChat();

  return (
    <div className="flex h-screen w-screen items-center justify-center">
      <h1>LOADING...</h1>
    </div>
  );
}
