import Cookies from 'universal-cookie';

const cookies = new Cookies();

export const isLoggedIn = () => {
  const token = cookies.get('access');
  return !!token;
};

// Note:  this function is use for retrying component which is lazy imported
// it may doesn't load component in rare case.
export const retry = (fn, retriesLeft = 5, interval = 1000) => {
  return new Promise((resolve, reject) => {
    fn()
      .then(resolve)
      .catch((error) => {
        if (retriesLeft <= 1) {
          reject(error);
          return;
        }
        setTimeout(() => {
          // Passing on "reject" is the important part
          retry(fn, retriesLeft - 1, interval).then(resolve, reject);
        }, interval);
      });
  });
};

export function fixUniqueStages(strArr) {
  let arr = strArr;
  if (typeof strArr === 'string') {
    arr = strArr.split(';');
  }
  arr = arr.map((i) => i.trim());
  return [...new Set(arr)].join(';');
}
