import { useEffect, useRef } from 'react';

import { InlineWidget, useCalendlyEventListener } from 'react-calendly';
import { useLocation } from 'react-router-dom';

import { ROUTES } from '@/_navigation/routes';
import { VITE_CALENDER_URL, VITE_PRE_BUY_CALENDER_URL } from '@/_static/constants';

function CalendlyView({ leadObj, currentObj, updateCategory, submitLeadQualification }) {
  const ref = useRef(null);
  const { pathname } = useLocation();
  const isPreBuy = pathname === ROUTES.BRIDGE_PLAN_PRE_BUY_RES_SCHEDULED.link;

  useCalendlyEventListener({
    onEventScheduled: () => {
      if (currentObj?.category) {
        updateCategory(currentObj?.category);
        submitLeadQualification();
        ref.current = true;
      }
    },
  });

  const prefill = {
    name: `${leadObj.First_Name__c} ${leadObj.Name}`,
    email: leadObj.Email__c,
    customAnswers: {
      a1: `1${leadObj.Mobile_Primary__c}`,
    },
  };
  const pageSettings = {
    primaryColor: 'FF4500',
  };

  const handleEvent = () => {
    if (ref?.current) {
      updateCategory(currentObj?.nextCategory);
      submitLeadQualification();
    }
  };

  useEffect(() => {
    window.addEventListener('beforeunload', handleEvent);

    return () => {
      window.removeEventListener('beforeunload', handleEvent);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    window.localStorage.removeItem('lq_calendly_fired');
    localStorage.setItem('lq_is_pre_buy_bp_purchased', isPreBuy ? '1' : '0');
  }, [isPreBuy]);

  return (
    <InlineWidget
      url={isPreBuy ? VITE_PRE_BUY_CALENDER_URL : VITE_CALENDER_URL}
      prefill={prefill}
      pageSettings={pageSettings}
      styles={{ height: 'calc(100vh - 128px)' }}
    />
  );
}

export default CalendlyView;
