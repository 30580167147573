import { useState, useEffect } from 'react';

import { twMerge } from 'atp-react-ui';
import { Tween } from 'react-gsap';
import { v1 as uuidv1 } from 'uuid';

import useStyles from './styles';

function Select({ options, currentObj, pageCallBack, currentPage }) {
  const classes = useStyles();
  const [isSelected, setIsSelected] = useState('');
  const [random, setIsRandom] = useState(null);

  useEffect(() => {
    let formData = localStorage.getItem('formData') ? localStorage.getItem('formData') : null;
    if (formData) {
      formData = JSON.parse(formData);
      const uiObj = localStorage.getItem('ui') ? JSON.parse(localStorage.getItem('ui')) : {};
      for (const [key, value] of Object.entries(formData)) {
        if (key === currentObj.api && value !== '') {
          setIsSelected(value);
          localStorage.setItem('ui', JSON.stringify({ ...uiObj, currentOptionValue: value }));
        }
      }
    }
    // evaluate if still on same page -- fixes animation issue where sometimes it will not render the animation on a new page render and prevents it from rendering multiple times on same page
    const uiObj = localStorage.getItem('ui') ? JSON.parse(localStorage.getItem('ui')) : {};
    const thisPage = uiObj?.thisPage ? uiObj.thisPage : currentPage;
    if (thisPage !== currentPage) {
      setIsRandom(uuidv1);
    }
    localStorage.setItem('ui', JSON.stringify({ ...uiObj, thisPage: currentPage }));
  }, [currentObj.api, currentPage]);

  const handleSelectClick = (e) => {
    const val = e.currentTarget.innerText;
    const formData = localStorage.getItem('formData') ? JSON.parse(localStorage.getItem('formData')) : {};
    const uiObj = localStorage.getItem('ui') ? JSON.parse(localStorage.getItem('ui')) : {};
    if (formData.length > 0) {
      for (const [key, value] of Object.entries(formData)) {
        if (key === currentObj.api) {
          formData[key] = value;
        }
      }
    } else {
      formData[currentObj.api] = val;
    }
    localStorage.setItem('formData', JSON.stringify(formData));
    localStorage.setItem('ui', JSON.stringify({ ...uiObj, currentOptionValue: val }));
    setIsSelected(val);
    pageCallBack(currentObj.api, val);
  };

  const list = options.map((obj, index) => {
    const buttonBg = currentObj?.buttonColor;
    const isLast = index === options.length - 1;

    return (
      <div
        className={twMerge(
          'mb-3.5 lg:mb-4',
          buttonBg,
          classes.options,
          isSelected === obj.option || obj.answerTestKey === isSelected ? classes.active : '',
          isLast && 'mb-0'
        )}
        onClick={handleSelectClick}
        key={`${random}_${index}`}
        dangerouslySetInnerHTML={{ __html: obj.option }}
      />
    );
  });

  return (
    <Tween
      inherit={false}
      lazy={false}
      from={{ x: '200px', opacity: 0 }}
      to={{ x: '0px', opacity: 1 }}
      stagger={0.05}
      ease="fade.out(0.55, 0.1)"
      key={random}
      duration={0.8}
    >
      {list}
    </Tween>
  );
}

export default Select;
