import { useEffect } from 'react';

import { QueryClientProvider } from '@tanstack/react-query';
import { AtpLib } from 'atp-react-ui';
import { RouterProvider } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';

import PagePreLoader from '@/_components/preloader';
import { queryClient } from '@/_utils';

import router from './root-router';

/**
 * info: 
 * Entry point for app logic
 * All global initializations and declarations are done here
  
 * <PersistGate:  Initialize State persistor
 * <ThemeProvider: Provide theme context to all code
 * <CssBaseline: Initialize all base css for old browsers
 * <ErrorBoundary: Capture unwanted bugs
 */

function EntryPoint() {
  useEffect(() => {
    document.getElementById('preloader').style.display = 'none';
  }, []);

  return (
    <AtpLib usePreferences={false} preferredMode="light">
      <QueryClientProvider client={queryClient}>
        <ToastContainer
          limit={5}
          position="bottom-left"
          autoClose={6000}
          hideProgressBar
          newestOnTop
          closeOnClick
          rtl={false}
          pauseOnHover
        />

        <RouterProvider
          router={router}
          fallbackElement={
            <div className="bg-primary-xx-light">
              <PagePreLoader fullScreen />
            </div>
          }
        />
      </QueryClientProvider>
    </AtpLib>
  );
}

export default EntryPoint;
