// all global settings

export const BASE_URL = import.meta.env.VITE_API_BASE_URL;

export const SUPPORTED_ENVIRONMENT = {
  DEVELOPMENT: 'development',
  PRODUCTION: 'production',
  STAGING: 'staging',
  LOCAL: 'local',
};

export const {
  VITE_API_BASE_URL,
  VITE_CONNECTED_PORTAL_URL,
  VITE_ENVIRONMENT,
  VITE_CALENDER_URL,
  VITE_PRE_BUY_CALENDER_URL,
} = import.meta.env;

export const isDevelopmentEnv = VITE_ENVIRONMENT === SUPPORTED_ENVIRONMENT.DEVELOPMENT;
export const isStagingEnv = VITE_ENVIRONMENT === SUPPORTED_ENVIRONMENT.STAGING;
export const isProductionEnv = VITE_ENVIRONMENT === SUPPORTED_ENVIRONMENT.PRODUCTION;

export const FILTER = new Set([
  'ATP_1__c',
  'ATP_2__c',
  'ATP_3__c',
  'ATP_4__c',
  'Lead__c',
  'Stage__c',
  'Email__c',
  'General_Education_Score__c',
  'ATP_Reject__c',
]);

export const TZ = Intl.DateTimeFormat().resolvedOptions().timeZone;
