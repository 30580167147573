import { validatePhoneNumber } from 'atp-react-ui';
import * as z from 'zod';

export const contactSchema = z
  .object({
    email: z.string().email({ message: 'Please provide valid Email Address' }),
    phone: z.string().length(10, { message: 'Please provide valid phone number' }),
  })
  .refine(
    (schema) => {
      if (schema.phone) {
        return validatePhoneNumber(schema.phone);
      }
      return true;
    },
    {
      message: 'Please provide valid phone number',
      path: ['phone'],
    }
  );
