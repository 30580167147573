import { twMerge } from 'tailwind-merge';

import { ROUTES } from '@/_navigation/routes';
import AsteriskIcon from '@/_static/assets/asterisk_logo.svg?react';
import AvatarLines from '@/_static/assets/avatarlines.svg?react';
import CongratsFlagIcon from '@/_static/assets/congratsFlagIcon.svg?react';
import CoreNursing from '@/_static/assets/coreNursing.svg?react';
import Entrance from '@/_static/assets/newEntranceExam.svg?react';
import Exit from '@/_static/assets/newExitExam.svg?react';
import GenEd from '@/_static/assets/newGeneralEducationIcon.svg?react';
import rebecca from '@/_static/assets/rebecca.jpg';
import { STYLE } from '@/_static/styles';
import { replaceWithName } from '@/_utils/actions';

import DangerousText from '../DangerousText';

import useStyles from './styles';

function MainCard({ currentObj, currentPage, leadObj }) {
  const classes = useStyles();

  const renderhtml = () => {
    return (
      <div className="mb-[40px] mr-[40px] mt-[70px] text-center">
        <span
          className="relative inline-block h-[120px] w-[120px] rounded-full bg-white"
          style={{ backgroundImage: `url(${rebecca})`, backgroundSize: 'contain' }}
        >
          <AvatarLines className="absolute -left-[84px] -top-[30px] h-[70px] w-[190px]" />
        </span>
      </div>
    );
  };
  const handleRenderIcon = (step) => {
    switch (step) {
      case 'Bridge 1/4':
        return <GenEd className={classes.stepIcon} />;
      case 'Bridge 2/4':
        return <Entrance className={classes.stepIcon} />;
      case 'Bridge 3/4':
        return <CoreNursing className="mb-2.5 mt-5 w-[90px] lg:mb-0 lg:w-[110px]" />;
      case 'Bridge 4/4':
        return <Exit className={classes.stepIcon} />;
      case 'Congrats':
        return <CongratsFlagIcon className="h-auto w-[60px] lg:w-[75px]" />;
      default:
        return <AsteriskIcon className="h-auto w-[48px] lg:w-[60px]" />;
    }
  };
  const html = renderhtml();
  const renderIcon = handleRenderIcon(currentObj?.step);
  return (
    <>
      {renderIcon}
      <div
        className={twMerge(
          STYLE.header,
          'mt-2.5 text-white lg:mt-5 lg:font-medium xl:font-medium',
          currentObj?.step && currentPage !== ROUTES.BRIDGE_PLAN_PRE_BUY_PURCHASED.link
            ? `${classes.mainStep} mb-2`
            : ''
        )}
      >
        <DangerousText>{replaceWithName(currentObj.header, leadObj)}</DangerousText>
      </div>
      <div
        className={twMerge(
          STYLE.header,
          'font-sans text-xl text-white xl:text-2xl',
          currentObj?.step && currentPage !== ROUTES.BRIDGE_PLAN_PRE_BUY_PURCHASED.link
            ? `${classes.mainStep} m-0 font-normal xl:text-xl xl:leading-10`
            : ''
        )}
      >
        <DangerousText>{currentObj.subheader}</DangerousText>
      </div>
      {currentObj?.mainStep === 'Step 2' ? html : null}
    </>
  );
}

export default MainCard;
