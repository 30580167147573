import LightLogo from '@/_static/assets/atp_white_icon.svg?react';

export function Layout({ children }) {
  return (
    <article className="h-screen bg-primary-x-light text-white">
      <main className="h-[calc(100vh_-_190px)] bg-primary">
        <section className="flex h-full w-full flex-col gap-2 p-8">
          <header className="flex h-16 justify-end">
            <LightLogo className="w-7 md:w-10" />
          </header>
          <div className="mx-auto flex w-full max-w-[_720px] flex-col justify-center gap-8 md:flex-row">{children}</div>
        </section>
      </main>
    </article>
  );
}
