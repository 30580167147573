import { StrictMode } from 'react';

import { createRoot } from 'react-dom/client';

// info: all global styles
import '@/_static/css/style.css';
import 'react-toastify/dist/ReactToastify.css';

import EntryPoint from './entry-point';

/**
 * Starting point of the app
 * It connects Js to HTMl
 * include all global styling here.
 * Strict mode works only in dev environment and helps in finding bugs.
 */

createRoot(document.getElementById('root')).render(
  <StrictMode>
    <EntryPoint />
  </StrictMode>
);
