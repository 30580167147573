import dayjs from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import IsBetween from 'dayjs/plugin/isBetween';
import isoWeek from 'dayjs/plugin/isoWeek';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';

dayjs.extend(customParseFormat);
dayjs.extend(isSameOrBefore);
dayjs.extend(isSameOrAfter);
dayjs.extend(IsBetween);
dayjs.extend(isoWeek);
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(advancedFormat);

function add(date, number, unit) {
  return dayjs(date).add(number, unit);
}

function subtract(d1, d2, period) {
  return dayjs(d1).subtract(d2, period);
}

function set(date, prop, value) {
  return dayjs(date).set(prop, value);
}

function format(date = new Date(), formatStr = 'MMM D, YYYY', isUTCEnabled = false) {
  return isUTCEnabled ? dayjs(dayjs(date).utc(true).format()).format(formatStr) : dayjs(date).format(formatStr);
}

function getDeviceTimeZone() {
  return dayjs.tz.guess();
}

function getDurationDifference(d1, d2, asa = 'month', float = true) {
  return dayjs(d1).diff(d2, asa, float);
}

function getInterval(start, end) {
  const n = getDurationDifference(end, start, 'day', false);
  if (n <= 0) return [];
  const arr = [dayjs(start)];
  for (let i = 0; i < n; i++) {
    arr[arr.length] = arr[arr.length - 1].add(1, 'day');
  }

  return arr;
}

function startOf(date = new Date(), off = 'week') {
  return dayjs(date).startOf(off);
}

function endOf(date = new Date(), off = 'week') {
  return dayjs(date).endOf(off);
}

function getDateOfMonth(date) {
  return dayjs(date).date();
}

function isDateSameOrAfter(d1, d2, t = 'date') {
  return dayjs(d1).isSameOrAfter(d2, t);
}

function isDateSameOrBefore(d1, d2, t = 'date') {
  return dayjs(d1).isSameOrBefore(d2, t);
}

function isInBetween(d, { startTime, endTime }, t = 'milliseconds') {
  return dayjs(d).isBetween(startTime, endTime, t);
}

function checkGreater(firstDateStr, secondDateStr = new Date()) {
  return dayjs(firstDateStr).isAfter(secondDateStr, 'date');
}

function getDayNumberOfWeek(dateStr = new Date().toString()) {
  return dayjs(dateStr).day();
}

function getDateOfDay(dateStr, dayNumber) {
  return dayjs(dateStr).day(dayNumber);
}

function getTimeInMilliSecond(d = new Date()) {
  return dayjs(d).valueOf();
}

function getDateByMilliSecond(milliSecond) {
  return new Date(milliSecond * 1000);
}

function utcDateString(d) {
  return dayjs(d).format();
}

function getDayNumberByDateOrWeek(date, myFormat) {
  // if myFormat == day, we would like to return day of the week
  // or else we will return the date of the month
  if (myFormat === 'day') {
    return dayjs(date).isoWeekday();
  }

  return parseInt(format(date, 'DD'), 10);
}

function timeZoneToUtc(date, timeZone = 'America/New_York') {
  return dayjs.tz(dayjs(date), timeZone);
}

function utcToTimezone(date, timeZone = 'America/New_York') {
  return dayjs.tz(date, timeZone);
}

function getFormattedDate(date, formatStr = 'M/D/YYYY', timeZone = getDeviceTimeZone(), isTimeStamp = false) {
  // if don't get timezone set current timezone of the user
  // let timeZone = oTimeZone;
  // if (!timeZone) timeZone = getDeviceTimeZone();

  const utcDate = timeZoneToUtc(date, timeZone);

  if (isTimeStamp) {
    return getTimeInMilliSecond(utcDate);
  }

  return format(utcDate, formatStr);
}

function isSame(d1, d2, t = 'date') {
  return dayjs(d1).isSame(d2, t);
}

function isBefore(d1, d2, t = 'date') {
  return dayjs(d1).isBefore(d2, t);
}

function isAfter(d1, d2, t = 'date') {
  return dayjs(d1).isAfter(d2, t);
}

function now(isUTC = false) {
  return isUTC ? dayjs().utc() : dayjs();
}

function doubleNumber(num) {
  const x = parseInt(num) > 9 || num === '00' ? `${num}` : `0${num}`;

  if (parseInt(x, 10) === 0) {
    return '00';
  }
  return x;
}

function getConvertedToEstStamp(date, time) {
  let [xHours, xMinutes] = time.split(':');
  const [month, day, year] = format(date, 'MM/DD/YYYY').split('/');
  let minutes = '00';
  let hours = xHours;

  if (xMinutes) {
    xMinutes = xMinutes.toUpperCase();
    if (xMinutes.indexOf('PM') > -1) minutes = xMinutes?.split('PM').join('');
    if (xMinutes.indexOf('AM') > -1) minutes = xMinutes.split('AM').join('');
  } else {
    xHours = xHours.toUpperCase();
    if (xHours.indexOf('PM') > -1) hours = xHours?.split('PM').join('');
    if (xHours.indexOf('AM') > -1) hours = xHours.split('AM').join('');
  }

  if (time.toUpperCase().indexOf('PM') > -1) {
    if (parseInt(xHours, 10) + 12 < 24) {
      hours = `${parseInt(xHours, 10) + 12}`;
    }
  }

  const strDateTime = `${year}-${month}-${day} ${doubleNumber(hours)}:${doubleNumber(minutes)}`;
  return dayjs.tz(strDateTime, 'America/New_York');
}

function parseFromEstStrToEst(timeDateStamp) {
  return dayjs(timeDateStamp).tz('America/New_York');
}

function getDeviceTimeInEST() {
  return dayjs.tz(dayjs(), 'America/New_York');
}

function parse(str, formatStr = '') {
  if (formatStr) {
    return dayjs(str, formatStr);
  }
  return dayjs(str);
}

function parseToUtc(d) {
  return dayjs.utc(d);
}

function getDateFromWeekDay(n) {
  return getDateOfDay(now().toISOString(), n).toISOString();
}

function getDateFromMonthDayNumber(n) {
  return set(now().toISOString(), 'date', n).toISOString();
}

function getMonthlyPaymentDate(date = now().toISOString(), stringReq = true) {
  // if date is above 28 make it 28
  let nDate = parse(date);
  const currentDateValue = nDate.format('D');
  if (parseInt(currentDateValue, 10) > 28) {
    nDate = nDate.date(28);
  }

  return stringReq ? nDate.toISOString() : nDate;
}

const dateTime = {
  now,
  add,
  subtract,
  set,
  startOf,
  endOf,
  getDateOfMonth,
  checkGreater,
  isDateSameOrAfter,
  isDateSameOrBefore,
  isInBetween,
  getDayNumberOfWeek,
  getDateOfDay,
  getTimeInMilliSecond,
  getDateByMilliSecond,
  utcDateString,
  format,
  getDurationDifference,
  getDayNumberByDateOrWeek,
  getInterval,
  timeZoneToUtc,
  getDeviceTimeZone,
  getFormattedDate,
  getConvertedToEstStamp,
  isSame,
  isBefore,
  isAfter,
  utcToTimezone,
  parseFromEstStrToEst,
  parse,
  parseToUtc,
  getDeviceTimeInEST,

  getDateFromWeekDay,
  getDateFromMonthDayNumber,
  getMonthlyPaymentDate,
  doubleNumber,
};

export default dateTime;

// window.dateTime = dateTime;
// window.dayjs = dayjs;
