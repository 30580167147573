import { Article, Footer, Header, Section, Container, Main } from '@/_containers';
import CATEGORIES from '@/_mappers/category-mapper';
import GOOGLE_EVENT_MAPPING from '@/_mappers/google-mappers';
import { useDataContext, useLeadContext, useAjaxHandler } from '@/_provider';
import { TZ } from '@/_static/constants';
import { buildScoreMap, updateLocalStorage, snackbar, fixUniqueStages, useWindowDimensions } from '@/_utils';

import { VideoCard } from './components/video-card';

export default function VideoPage() {
  const { currentPage, currentObj, currentQuestions, sendGoogleEvent, setIsLoading, setEnableButton, updateCategory } =
    useDataContext();
  const { navigate, data, noLead } = useLeadContext();
  const { l: leadObj } = data;

  const props = {
    navigate,
    currentObj,
    currentPage,
    leadObj,
    currentQuestions,
    sendGoogleEvent,
  };

  const { mutateAsync: mutateLeadQualification } = useAjaxHandler();

  const { width } = useWindowDimensions();

  const handleGoogleEvent = (page, isSingle = false) => {
    const ui = localStorage.getItem('ui') ? JSON.parse(localStorage.getItem('ui')) : null;
    const uiArr = ui?.ga_single_events ? ui.ga_single_events : [];
    const googleEvent = GOOGLE_EVENT_MAPPING[page];

    if (isSingle) {
      if (!uiArr.includes(page)) {
        uiArr.push(page);
        updateLocalStorage('ui', { ga_single_events: uiArr });
        sendGoogleEvent(googleEvent);
      }
    } else {
      sendGoogleEvent(googleEvent);
    }
  };

  const breakpoints = (d_width) => {
    let val = null;
    if (d_width <= 359) {
      val = 'xxs';
    } else if (d_width >= 360 && d_width < 600) {
      val = 'xs';
    } else if (d_width >= 600 && d_width < 960) {
      val = 'sm';
    } else if (d_width >= 960 && d_width < 1280) {
      val = 'md';
    } else if (d_width >= 1280 && d_width < 1920) {
      val = 'lg';
    } else if (d_width >= 1920) {
      val = 'xl';
    }
    return val;
  };

  const submitLeadQualification = async (onLoad = false, callback) => {
    if (!noLead) {
      let fd = localStorage.getItem('formData') ? JSON.parse(localStorage.getItem('formData')) : null;

      const scoreMap = buildScoreMap(fd);
      const fd_stage = fd?.Stage__c ?? null;

      const splitStage = fd_stage ? fd_stage.split(' ') : null;
      let stage_score = splitStage ? `${splitStage.join('_')}_Score__c` : null;
      const fd_score = scoreMap.get(fd_stage);

      if (stage_score && fd_score) {
        if (fd_stage === CATEGORIES.ENTRANCE_EXAM) {
          stage_score = 'Entrance_Exam_Score__c';
        } else if (fd_stage === CATEGORIES.LICENSURE_EXAM || fd_stage === CATEGORIES.EXIT_EXAM) {
          stage_score = 'Exit_Exam_Score__c';
        } else if (fd_stage === CATEGORIES.CORE_RN_COURSES) {
          stage_score = 'Core_Nursing_Courses_Score__c';
        }

        updateLocalStorage('formData', { [stage_score]: fd_score });
      }

      if (fd_stage) {
        const stageArr = fd.Stages_Completed__c.split(';');
        if (!stageArr.includes(fd_stage)) {
          stageArr.push(fd_stage);
        }
        let Calendly_Appointment_Scheduled__c;
        let Reservation_Confirmed_Score__c;
        if (fd_stage === CATEGORIES.READY_SOON_RES_CONFIRMED || fd_stage === CATEGORIES.READY_NOT_YET_RES_CONFIRMED) {
          Reservation_Confirmed_Score__c = 50;
        }
        if (fd_stage === CATEGORIES.READY_SOON_RES_SCHEDULE) {
          Calendly_Appointment_Scheduled__c = true;
        }

        updateLocalStorage('formData', {
          Calendly_Appointment_Scheduled__c,
          Reservation_Confirmed_Score__c,
          Stage__c: fd_stage,
          Stages_Completed__c: fixUniqueStages(stageArr),
        });
      }

      fd = localStorage.getItem('formData') ? JSON.parse(localStorage.getItem('formData')) : null;
      const campaign = localStorage.getItem('campaign') ? JSON.parse(localStorage.getItem('campaign')) : null;
      const lid = fd.Lead__c;

      const leadInfo = {
        firstName: leadObj.First_Name__c,
        lastName: leadObj.Name,
        email: leadObj.Email__c,
        campaign,
        formName: 'Lead Qualification',
        timezone: TZ,
        lq_app: true,
      };

      const json = {
        action: 'submitLeadQualification',
        leadId: lid,
        userInfo: fd,
        leadInfo,
      };

      if (fd_stage === CATEGORIES.GENERAL_EDUCATION && fd?.ATP_6__c && fd.ATP_6__c !== 'Not important.') {
        json.leadInfo.pilot = true;
        json.updateLead = true;
      }

      if (currentPage === '0_4' || onLoad) {
        json.updateLead = true;
      }

      if (fd_stage === CATEGORIES.READY_NOT_YET_LEARN_HOME) {
        json.updateLead = true;
      }

      mutateLeadQualification(json)
        .then(() => {
          if (callback) {
            callback();
          }
          return null;
        })
        .catch(() => {
          snackbar.error('Something went wrong please try again');
        });
    }
  };

  const handleClick = async () => {
    if (currentObj.allowCategoryAndUpload) {
      updateCategory(currentObj.category);
      handleGoogleEvent(currentPage);
      setIsLoading(true);
      submitLeadQualification('', () => {
        setIsLoading(false);
        navigate(currentObj?.nextPage);
      });
      return;
    }
    navigate(currentObj?.nextPage);
  };

  return (
    <Article>
      <Container>
        <Section>
          <Header />
          <Main>
            <VideoCard
              {...props}
              updateCategory={updateCategory}
              setEnableButton={setEnableButton}
              breakpoints={breakpoints(width)}
              handleGoogleEvent={handleGoogleEvent}
              submitLeadQualification={submitLeadQualification}
            />
          </Main>
        </Section>
      </Container>

      <Footer handleClick={handleClick} keepButtonEnabled />
    </Article>
  );
}
