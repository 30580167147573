import { useMutation } from '@tanstack/react-query';

import { apiClient } from '@/_utils';

export function useAjaxHandler() {
  return useMutation({
    mutationFn: async (payload) =>
      apiClient.post({
        endpoint: 'ajaxHandler.php',
        payload,
      }),
  });
}
