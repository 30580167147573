import CATEGORIES from './category-mapper';

const RESULTS_MAPPING_APIKEY = new Map([
  [CATEGORIES.GENERAL_EDUCATION, 'ATP_5__c'],
  [CATEGORIES.ENTRANCE_EXAM, 'ATP_7__c'],
  [CATEGORIES.CORE_RN_COURSES, 'ATP_9__c'],
  [CATEGORIES.EXIT_EXAM, 'ATP_11__c'],
]);

export default RESULTS_MAPPING_APIKEY;
