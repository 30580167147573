import { useCallback, useRef, useEffect } from 'react';

import { Text } from 'atp-react-ui';
import { useNavigate, Outlet, useLocation } from 'react-router-dom';

import CATEGORIES from '@/_mappers/category-mapper';
import CATEGORY_TO_RESUME_PATH_MAPPER from '@/_mappers/resuming-mapper';
import { ROUTES } from '@/_navigation/routes';
import LightLogo from '@/_static/assets/atp_white_icon.svg?react';
import model1 from '@/_static/assets/model-1.jpg';
import { getParameterByName, getCookie, logger, setCookie, updateLocalStorage, initCampaignObj } from '@/_utils';
import dateTime from '@/_utils/date-time';

import { useRetellAI } from '../use-retellai';

import { LeadContext } from './lead-context';
import { useLeadQuery } from './use-lead-query';

export function LeadProvider() {
  const initRef = useRef(false);
  const nav = useNavigate();
  const { pathname } = useLocation();
  const paramLeadId = getParameterByName('lid');
  const cookieLeadId = getCookie('lid');
  const leadId = paramLeadId || cookieLeadId;
  const leadInfo = useLeadQuery({ action: 'getLeadQualificationInfo', leadId }, { skip: !leadId });
  const { isSuccess, data } = leadInfo;

  const navigate = useCallback(
    (page) => {
      setTimeout(() => {
        nav(page);
      }, 100);
    },
    [nav]
  );

  const initializeLQP = useCallback(
    (campaignObj, lsObj) => {
      localStorage.clear();
      lsObj.Stage__c = CATEGORIES.START_QUALIFY;
      lsObj.Stages_Completed__c = [lsObj.Stage__c].join(';');
      updateLocalStorage('formData', lsObj);
      updateLocalStorage('campaign', { ...campaignObj });
      updateLocalStorage('ui', { pageAnalytics: [ROUTES.QUALIFY.link] });
      navigate(ROUTES.QUALIFY.link);
    },
    [navigate]
  );

  const handleRestoreSession = useCallback(
    (resumingStagePath) => {
      const categoryObj = CATEGORY_TO_RESUME_PATH_MAPPER.find(
        (item) => item.category.toLowerCase() === resumingStagePath.toLowerCase()
      );

      if (!categoryObj) {
        navigate(ROUTES.BRIDGE_RESULTS.link);
        return;
      }

      navigate(categoryObj.link);
    },
    [navigate]
  );

  if (!initRef.current && isSuccess && data && data.l && leadId) {
    initRef.current = true;
    // set logger id
    logger.setId({ lead_id: leadId });

    // if param and cookie lead id are different, reset local storage
    if (paramLeadId !== cookieLeadId) {
      setCookie('lid', leadId, 365);
      updateLocalStorage('formData', {});
      updateLocalStorage('campaign', {});
      updateLocalStorage('ui', {});
    }

    const { l, lq } = data;
    const { Email__c, Id } = l;
    const lsObj = { Email__c, Lead__c: Id };
    const campaignObj = initCampaignObj();
    let hasFiveMinExpired = false;

    // initialize local storage if LQ is not available
    if (!lq) {
      initializeLQP(campaignObj, lsObj);
    } else {
      const { Last_Stage_Completed_TimeStamp__c, Stage__c } = lq;
      const lastUpdatedTime = dateTime.parseToUtc(Last_Stage_Completed_TimeStamp__c);

      if (dateTime.getDurationDifference(dateTime.now(true), lastUpdatedTime, 'minute', false) > 5) {
        hasFiveMinExpired = true;
      }

      const lqFilter = new Set(['Id', 'attributes']);
      for (const [key, val] of Object.entries(lq)) {
        if (!lqFilter.has(key)) {
          lsObj[key] = val;
        }
      }

      // const setLqStages = // new Set([...Stages_Completed__c.split(';')]);
      //   setLqStages.delete(CATEGORIES.MINDSET_REPATH_NOT_READY);
      // const lqStages = Stage__c;
      // lqStages.pop();

      updateLocalStorage('formData', lsObj);
      updateLocalStorage('campaign', { ...campaignObj });

      // restore session only when not in qualify page
      // const resumingStagePath = [ROUTES.QUALIFY.link].includes(pathname) ? null : pathname;

      if (hasFiveMinExpired) {
        // if 5 minutes has passed, reset the session

        localStorage.clear();
        lsObj.Stage__c = CATEGORIES.START_QUALIFY;
        updateLocalStorage('formData', lsObj);
        updateLocalStorage('campaign', { ...campaignObj });
        updateLocalStorage('ui', { pageAnalytics: [ROUTES.QUALIFY.link] });
        navigate(ROUTES.QUALIFY.link);
      } else if ([ROUTES.QUALIFY.link].includes(pathname)) {
        let lqStage = Stage__c;
        // legacy handling
        if ([CATEGORIES.BRIDGE_PLAN_PURCHASED, CATEGORIES.BP_PRE_BUY_PURCHASED].includes(lqStage)) {
          lqStage = CATEGORIES.BP_PRE_BUY_PURCHASED;
        }
        handleRestoreSession(lqStage);
      }
    }
  }

  const { l: leadObj } = data;
  const noLead = leadObj?.First_Name__c === 'Hey';

  return (
    <LeadContext.Provider value={{ ...leadInfo, navigate, noLead }}>
      {isSuccess && data && data.l && data.l.Retell__c ? <WaitMsg id={leadId} /> : <Outlet />}
    </LeadContext.Provider>
  );
}

function WaitMsg({ id }) {
  const { mutateAsync: mutateRetelAiAsync } = useRetellAI(id);

  useEffect(() => {
    if (id) {
      mutateRetelAiAsync();
    }
  }, [id, mutateRetelAiAsync]);

  return (
    <div
      style={{ backgroundImage: `url(${model1})` }}
      className="m-0 box-border h-screen w-screen bg-cover bg-[90%_center] bg-no-repeat md:bg-[right_center]"
    >
      <div className="container mx-auto flex h-full max-w-full flex-col px-6 sm:max-w-[44rem] sm:px-4 md:max-w-[48rem] md:px-6 pre-lg:max-w-[60rem] pre-lg:px-4 lg:max-w-none lg:px-11 pre-xl:px-4 xl:max-w-7xl xl:px-3">
        <header className="flex justify-end py-12 text-right">
          <LightLogo className="h-12 w-12" />
        </header>
        <main className="my-auto flex h-full select-none flex-col items-start justify-end pb-12 md:p-6 md:pb-32">
          <div className="w-full max-w-full rounded-2xl bg-white/60 px-6 py-8 md:w-[26rem]">
            <Text as="h1" className="text-accent-dark font-serif text-4xl lg:text-5xl">
              Thanks for your interest.
            </Text>
            <Text as="h2" className="text-accent-dark mt-4 font-sans text-3xl font-medium lg:mt-8 lg:text-4xl">
              We will call you within the next 15 minutes.
            </Text>
          </div>
        </main>
      </div>
    </div>
  );
}
