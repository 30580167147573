import { Player } from '@lottiefiles/react-lottie-player';

import WatchAnimation from '@/_static/animations/a_watch.json';

export default function AnimationPlayer({
  animation = WatchAnimation,
  size = 'size-36',
  loop = true,
  autoplay = true,
}) {
  return <Player autoplay={autoplay} loop={loop} src={animation} className={size} />;
}
