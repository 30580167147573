import { STYLE } from '@/_static/styles';
import { replaceWithName } from '@/_utils/actions';

import DangerousText from '../DangerousText';

import Contact from './components/Contact';
import Select from './components/Select';
import TextArea from './components/TextArea';

function FormCard({ navigate, currentObj, leadObj, pageCallBack, currentPage, setEnableButton, form }) {
  const renderFormInput = () => {
    if (currentObj?.list && currentObj.list.length > 0) {
      return currentObj.list.map((field, index) => {
        if (field.input === 'select') {
          return (
            <Select
              options={field.options}
              currentObj={currentObj}
              key={index}
              pageCallBack={pageCallBack}
              navigate={navigate}
              currentPage={currentPage}
              setEnableButton={setEnableButton}
            />
          );
        }

        if (field.input === 'textarea') {
          return (
            <TextArea currentObj={currentObj} leadObj={leadObj} field={field} key={field} pageCallBack={pageCallBack} />
          );
        }

        if (field.input === 'contact_form') {
          return <Contact form={form} key={field} />;
        }
        return null;
      });
    }
    return null;
  };

  return (
    <>
      <div className={currentObj?.bubbleChat ? STYLE.bubbleChat : STYLE.header}>
        <DangerousText>{replaceWithName(currentObj.header, leadObj)}</DangerousText>
      </div>
      <div>{renderFormInput(currentObj)}</div>
    </>
  );
}

export default FormCard;
