import * as z from 'zod';

import dateTime from '@/_utils/date-time';

export const checkoutSchema = z
  .object({
    name: z.string().nonempty({ message: 'Please provide correct name' }),
    card_number: z.string().nonempty({ message: 'Invalid Credit Card number' }),
    expiry: z.string().min(4, { message: 'Invalid Expiry Date' }).max(4, { message: 'Invalid Expiry Date' }),
    cvv: z.string().nonempty({ message: 'Invalid Security Code' }),
    postal_code: z.string().min(5, { message: 'Invalid Postal Code' }).max(5, { message: 'Invalid Postal Code' }),
    email: z.string().email({ message: 'Invalid Email Address' }),
    agree: z.boolean(),
  })
  .refine(
    (schema) => {
      const { isValid } = validateCCExpiry(schema.expiry);
      return isValid;
    },
    {
      message: 'Invalid Expiry Date',
      path: ['expiry'],
    }
  )
  .refine(
    (schema) => {
      return schema.agree;
    },
    {
      message: 'Please accept the Terms & Conditions',
      path: ['agree'],
    }
  );

export const validateCCExpiry = (strValue = '') => {
  if (strValue.length === 4) {
    const { month, year } = getMonthYear(strValue);

    if (month <= 12 && year >= dateTime.now().get('year')) {
      const dateCurrent = new Date(year, month, 1);
      const completeDateDayJs = dateTime.parse(dateCurrent);
      if (completeDateDayJs.isValid()) {
        if (dateTime.isBefore(dateTime.now(), completeDateDayJs, 'month')) {
          return { isValid: true, completeDate: completeDateDayJs.subtract(1, 'month').toDate().toISOString() };
        }
      }
    }
  }

  return { isValid: false, completeDate: null };
};

export const getMonthYear = (strValue = '') => {
  if (strValue.length === 4) {
    const [m0, m1, y0, y1] = strValue;
    const month = m0 + m1;
    const year = y0 + y1;

    return { month: parseInt(month, 10), year: parseInt('20' + year, 10) };
  }
  return { month: null, year: null };
};

export const defaultPattern = '#### #### #### ####';
export const defaultCVVPattern = '###';
export const boxStyle = ''; // 'border border-primary bg-white pb-1 pl-2.5 pt-2';
export const formItemStyle = 'space-y-1';
export const inputStyle = 'placeholder:text-accent-light/60'; //'border-0 py-1 pl-0 text-accent  focus:ring-0';

export function cardPatternGenerator(card) {
  if (card) {
    const { gaps, lengths } = card;

    const pattern = Array(lengths[0]).fill('#');
    let patternAdded = 0;
    for (let i = 0; i < gaps.length; i++) {
      pattern.splice(gaps[i] + patternAdded, 0, ' ');
      patternAdded++;
    }
    return { pattern: pattern.join(''), card_number_length: lengths[0] };
  }

  return { pattern: defaultPattern, card_number_length: 16 };
}
