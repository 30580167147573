import ArrowRightIcon from '@heroicons/react/24/outline/ArrowRightIcon';
import { Button } from 'atp-react-ui';

import { ProgressBar } from '@/_components';
import { useDataContext } from '@/_provider';

export function Footer({ handleSubmission, handleClick, keepButtonEnabled }) {
  const { currentObj, isLoading, enableButton } = useDataContext();

  const handlePageCount = () => {
    const locallyStoredFormData = localStorage.getItem('formData');
    const formData = locallyStoredFormData ? JSON.parse(locallyStoredFormData) : null;

    const arr = new Set();
    const ignoreQuestions = new Set(['ATP_3__c']);
    const ignoreAPImap = new Map([
      ['ATP_5__c', 'ATP_6__c'],
      ['ATP_7__c', 'ATP_8__c'],
      ['ATP_9__c', 'ATP_10__c'],
      ['ATP_11__c', 'ATP_12__c'],
    ]);

    let i = 1;
    while (i < 13) {
      const labelValue = formData[`ATP_${i}__c`];
      if (
        labelValue !== undefined &&
        labelValue &&
        labelValue.includes(`I've passed`) &&
        labelValue !== `I've passed some, but not all of them.`
      ) {
        if (ignoreAPImap.get(`ATP_${i}__c`) != null) {
          ignoreQuestions.add(ignoreAPImap.get(`ATP_${i}__c`));
        }
      }
      i++;
    }

    let j = 1;
    while (j < 13) {
      if (!ignoreQuestions.has(`ATP_${j}__c`)) {
        arr.add(`ATP_${j}__c`);
      }
      j++;
    }
    const nOfQuestions = arr.size;
    let nOfQuestionsCompleted = 0;

    if (formData) {
      for (const [key, value] of Object.entries(formData)) {
        if (arr.has(key) && value !== '') {
          nOfQuestionsCompleted++;
        }
      }
    }
    return [nOfQuestionsCompleted, nOfQuestions];
  };

  const pageCount = handlePageCount();

  const handleDisableButton = () => {
    if (keepButtonEnabled) return false;

    const fd = localStorage.getItem('formData') ? JSON.parse(localStorage.getItem('formData')) : null;
    // conditions to ignore disabling the button
    if (
      (currentObj?.informative && !currentObj?.disableButton) ||
      currentObj?.api === 'contact_form' ||
      currentObj?.enableButton
    ) {
      return false;
    }
    // iterate over the formData to see if value exists
    if (fd) {
      for (const [key, value] of Object.entries(fd)) {
        if (key === currentObj?.api && value !== '') {
          return false;
        }
      }
    }
    return true;
  };

  const handleProgress = (a, b) => {
    if (a && b) {
      return b > 0 ? (a / b) * 100 : 0;
    }
    return null;
  };

  const disableButton = handleDisableButton();
  const progress = handleProgress(pageCount[0], pageCount[1]);

  return (
    <footer
      className={`flex flex-col items-center justify-center px-4 py-8 md:px-10 ${
        currentObj?.hideFooter ? 'hidden' : null
      } ${currentObj?.footerColor ?? 'bg-primary-xx-light'}`}
    >
      <Button
        onClick={handleClick}
        disabled={disableButton && !enableButton}
        size="lg"
        theme="primary"
        className={`w-48 rounded-none text-xl font-bold disabled:border-primary-light disabled:bg-primary-light disabled:text-primary-medium ${
          currentObj?.isSubmission ? 'hidden' : ''
        }`}
        showLoader={isLoading}
        iconRight={currentObj?.hideButtonIcon ? null : ArrowRightIcon}
      >
        {currentObj.buttonText}
      </Button>

      <Button
        disabled={disableButton && !enableButton}
        showLoader={isLoading}
        theme="primary"
        size="lg"
        onClick={() => handleSubmission(currentObj?.api)}
        iconRight={currentObj?.hideButtonIcon ? null : ArrowRightIcon}
        className={`w-48 rounded-none text-xl font-bold disabled:border-primary-light disabled:bg-primary-light disabled:text-primary-medium ${
          currentObj?.isSubmission ? '' : 'hidden'
        }`}
      >
        {currentObj?.buttonText}
      </Button>

      {/* {buttonInfo} */}
      <section className={`w-[37.3333333333rem] max-w-full ${currentObj?.hideProgress ? 'hidden' : ''}`}>
        <ProgressBar value={progress} label="" main currentObj={currentObj} pageCount={pageCount} defaultProgress />
      </section>
    </footer>
  );
}
